<template>
  <b-card class="invision-card elevate-2 invision-top borderless">
    <b-card-header>
      Para acessar esta página é necessário se autenticar
    </b-card-header>
    <div class="mt-4">
      <b-form @submit.prevent="login">
        <b-form-group label="Registro*" label-for="userRegistro">
          <b-form-input
            class="invision-input"
            id="userRegistro"
            v-model="registro"
            required
            :disabled="isBusy"
          />
        </b-form-group>
        <InputSenha v-model="password" />
        <b-card-footer class="invision-card-footer-action">
          <b-row>
            <transition name="fade">
              <b-col class="ml-4 d-flex text-danger" v-if="error">
                <x-circle-icon class="mr-2" />
                <div>{{ error }}</div>
              </b-col>
              <b-col class="ml-4 d-flex text-success" v-else-if="success">
                <check-circle-icon class="mr-2" />
                <div>Sucesso! Redirecionando...</div>
              </b-col>
            </transition>
            <b-col class="text-right">
              <b-btn
                variant="success"
                type="submit"
                class="invision-btn lg"
                :disabled="isBusy"
              >
                <div class="d-flex" v-if="!isBusy">
                  <log-in-icon class="mr-2" />
                  <div>Entrar</div>
                </div>
                <b-row align-v="center" v-else
                  ><b-spinner
                    variant="light"
                    label="Entrando"
                    small
                    class="mr-2"
                  />
                  <div>Entrando...</div></b-row
                >
              </b-btn>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import loginService from '@/services/login';
import { wait } from '@/helpers/common';
import InputSenha from '@/components/Form/InputSenha';

export default {
  name: 'Login',
  components: {
    InputSenha,
  },
  data() {
    return {
      registro: '',
      password: '',
      isBusy: false,
      error: '',
      success: false,
    };
  },
  methods: {
    async login() {
      this.isBusy = true;
      this.error = '';
      this.success = false;
      try {
        const response = await loginService.login(this.registro, this.password);
        if (response.success) {
          this.$root.$emit('loginChange');
          this.success = true;
          await wait(1000);
          if (loginService.getUser().redefinicao) {
            this.$router.replace('/redefinicao');
          } else {
            this.$router.replace(this.$route.params.next || '/');
          }
          this.isBusy = false;
          this.registro = '';
          this.password = '';
          this.success = false;
        } else {
          throw new Error(response.error);
        }
      } catch (e) {
        this.isBusy = false;
        this.error = e || 'Erro desconhecido, por favor tente novamente.';
      }
    },
    logout() {
      loginService.logout();
      this.$root.$emit('loginChange');
    },
    validate() {
      this.isBusy = true;
      if (loginService.isLoggedIn()) {
        this.success = true;
        if (loginService.getUser().redefinicao) {
          this.$router.replace('/redefinicao');
        } else {
          this.$router.replace(this.$route.params.next || '/');
        }
      } else {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style>
*:focus {
  outline: 0 !important;
}
</style>
